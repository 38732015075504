body {
	font-family: InfoTextOT, Arial;
	margin: 0;
	color: #fff;
	background: #363b4b;
	overscroll-behavior: none;
}

a {
	color: rgb(30, 165, 255);
}


.accordion {
	color: inherit;
	text-decoration: none;
	cursor: pointer;
	padding: 18px;
	width: 100%;
	border: none;
	text-align: left;
	outline: none;
	font-size: 15px;
	transition: 0.4s;
}

.accordion-panel {
	padding: 0 18px;
	max-height: 0;
	overflow-x: hidden;
	overflow-y: auto;
	transition: max-height 0.2s ease-out;
}


.main-container {
	display: flex;
	flex-direction: row;
	position: absolute;
	z-index: 2;
	width: 100%;
	height: 100%;
	overflow: hidden;
}


.tab-label {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	left: 0;
	height: 25%;
	width: 1.5em;
	color: white;
	font-weight: normal;
	font-size: 1.3em;
	border: 0;
	border-radius: 0;
	outline: 0;
	cursor: pointer;
	z-index: 98;
}

.tab-label:hover {
	filter: brightness(120%);
}

/* Palette: https://coolors.co/f9c80e-f86624-ea3546-662e9b-43bccd */
#blocks-btn {
	top: 0%;
	background-color: #F9C80E;
}

#blocks-btn i {
	transform: rotate(90deg) scaleX(-1);
}

#viewport-btn {
	top: 25%;
	background-color: #F86624;
}

#tutorial-btn {
	top: 50%;
	background-color: #EA3546;
}

#about-btn {
	top: 75%;
	background-color: #662E9B;
}

#first-time-hint {
	position: absolute;
	color: #EA3546;  /* deeppink */
	text-shadow: 1px 1px 2px black;
	font-size: 2em;
	left: 1em;
	animation: note-me-bounce 2s ease infinite;
	transform-origin: left;
}


#iframe-notice {
	position: absolute;
	left: 0;
	bottom: 0;
	z-index: 999;
	padding: 5px 10px 5px 7px;
	background-color: #777;
	border-radius: 0 5px 0 0;
}


.gutter.gutter-horizontal {
	cursor: ew-resize;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=');
	background-repeat: repeat-y;
	width: 6px;  /* single dashed line at 4px, double at 6px */
	height: 100%;
	margin: 0 2px 0 2px;
	flex-shrink: 0;
}


.loading-lightbox {
	background: rgba(0, 0, 0, 0.5);
	position: absolute;
	z-index: 200;
	width: 100%;
	height: 100%;
}

.loading-text {
	position: absolute;
	top: 50%;
	right: 50%;
	transform: translate(50%, -50%);
	text-align: center;
	z-index: 2;
}

.loading-text {
	font-size: large;
	font-weight: bold;
}


#overlay {
	position: fixed;
	display: none;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 99;
}


.round-icon {
	display: inline;
	width: 40px;
	height: 40px;
	border-radius: 100%;
	background-color: #777777;
	margin: 5px;
	text-align: center;
}

.round-icon::before {
	font-size: 1.3em;
	position: relative;
	top: 25%;
}

a .round-icon {
	color: white;
}

a:hover .round-icon {
	color: rgb(30, 165, 255);
}


.desktop-only {
	display: initial;
}

.mobile-only {
	display: none;
}

@media (hover: none) and (pointer: coarse) {
	.desktop-only {
		display: none;
	}

	.mobile-only {
		display: initial;
	}
}


@keyframes note-me-bounce {
	0%   { transform: scale(1,1)      translateX(0); }
	10%  { transform: scale(.9,1.1)   translateX(0); }
	30%  { transform: scale(1.1,.9)   translateX(.7em); }
	50%  { transform: scale(1.05,.95) translateX(0); }
	57%  { transform: scale(1,1)      translateX(.1em); }
	64%  { transform: scale(1,1)      translateX(0); }
	100% { transform: scale(1,1)      translateX(0); }
}
